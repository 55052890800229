/* Font Face */
@font-face {
  font-family: 'Bebas Neue';
  src: url('./components/BebasNeue-Regular.otf') format('truetype');
}

/* General Styling */
body {
  background: linear-gradient(135deg, #ffffff 0%, #e3f2fd 100%);
  font-family: 'Bebas Neue', sans-serif;
  margin: 0;
  padding: 0;
  color: #212121;
}

p {
  color: #333;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  line-height: 1.6;
}

/* Iframe Styling */
iframe {
  width: 100%;
  height: 100%;
}

.iframe {
  margin: 5% auto;
  height: 81vh;
  width: 79%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

/* Contact Section */
.contact {
  text-align: center;
  font-size: clamp(1.5rem, 2vw, 1.8rem);
  margin-top: 4.5%;
}

.contact a {
  display: inline-block;
  color: #212121;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: #e3f2fd;
  transition: all 0.3s ease-in-out;
}

.contact a:hover {
  color: #ffffff;
  background-color: #1976d2;
  text-decoration: underline;
}

/* Instagram Button */
.fa {
  padding: 15px;
  font-size: clamp(1.5rem, 2vw, 2rem);
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.fa:hover {
  opacity: 0.8;
}

.fa-instagram {
  color: #d6249f;
  background: linear-gradient(45deg, #feda75, #d6249f, #285aeb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Navbar */
/* Modern Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background: linear-gradient(135deg, #ffffff, #f9f9f9);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar img {
  width: 150px; /* Adjusted size */
  max-width: 100%;
}

.navbar-buttons {
  display: flex;
  gap: 1.5rem; /* Add space between buttons */
}

.navbarBtn {
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  border: none;
  background: black;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50px; /* Rounded buttons for a modern look */
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.navbarBtn:hover {
  background: white;
  color: black;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

.navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-icon {
  font-size: 1.5rem;
  color: #d6249f;
  background: linear-gradient(45deg, #feda75, #d6249f, #285aeb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: auto;
  margin-right: 5%;
  transition: transform 0.3s ease;
}

.navbar-icon:hover {
  transform: scale(1.1);
}

/* Videos Section */
.videosHead {
  text-align: center;
  font-weight: bold;
  font-size: clamp(1.5rem, 2vw, 2rem);
  margin: 20px 0;
}

.videosList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  padding: 1%;
}

.videoImg {
  transition: all 0.3s ease-in-out;
  border: 2px solid transparent;
  border-radius: 12px;
}

.videoImg:hover {
  opacity: 0.9;
  border-color: #64b5f6;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.image-container:hover {
  transform: scale(1.05);
}

.image-container img {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.image-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  color: white;
  font-size: 1.2rem;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.image-container:hover .image-caption {
  visibility: visible;
}

/* Homepage */
.homepage {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 5% auto;
  max-width: 1200px;
  padding: 0 5%;
}

.homepageintro {
  flex: 1;
  backdrop-filter: blur(10px);
  font-family: 'Oswald', sans-serif;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  font-weight: 300;
  color: #555;
}

.homepagephoto {
  flex: 1;
  display: flex;
  justify-content: center;
}

.homepagephoto img {
  width: 100%;
  max-width: 500px;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

/* Logos */
.logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 5%;
}

.logoImg {
  width: 15%;
  min-width: 100px;
  transition: transform 0.3s ease-in-out;
}

.logoImg:hover {
  transform: scale(1.2);
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .videosList {
    flex-direction: column;
  }

  .homepage {
    flex-direction: column;
  }

  .homepagephoto img {
    width: 90%;
  }

  .logos {
    flex-direction: column;
  }

  .logoImg {
    width: 30%;
  }
}

/* Responsive Navbar */
@media only screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    gap: 1rem;
  }

  .navbar-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .navbar-icon {
    margin: 0 auto;
  }
}
